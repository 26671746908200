import * as React from "react";
import Navbar from "../components/Navbar";
import {
  SimpleGrid,
  Box,
  Heading,
  Text,
  Link,
  Button,
  Center,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import Helmet from "react-helmet";

const Projects = () => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <meta name="description" content="The projects made by Akaash Sinha" />
        <title>Akaash Sinha | Projects</title>
      </Helmet>
      <Navbar />
      <SimpleGrid columns={[1, null, 2]} spacing={10}>
        <Box mx="auto" borderRadius={"lg"} borderWidth={1} shadow={"md"} p={4}>
          <Box my={2}>
            <Link
              href="https://pokenext-topaz.vercel.app/"
              isExternal
              _hover={{ color: "blue.500" }}
            >
              <Heading textAlign={"center"}>{"PokéNext"}</Heading>
            </Link>
          </Box>
          <Box>
            <StaticImage
              src="../images/pokenext.png"
              alt="BlackPages.io"
              placeholder="blurred"
              width={500}
              height={260}
            />
          </Box>
          <Box my={3}>
            <Text>{"A Pokédex with a Who's That Pokemon mini-game."}</Text>
            <Text>
              {"Built with: Next.js, TailwindCSS, GraphQL, and the PokéAPI."}
            </Text>
            <Link
              href="https://github.com/akaashsinha/pokenext"
              isExternal
              _hover={{ color: "blue.500" }}
            >
              <Center>
                <Button aria-label={`Source Code: PokeNext`} my={2}>
                  Source Code
                </Button>
              </Center>
            </Link>
          </Box>
        </Box>

        <Box mx="auto" borderRadius={"lg"} borderWidth={1} shadow={"md"} p={4}>
          <Box my={2}>
            <Heading textAlign={"center"}>BlackPages.io</Heading>
          </Box>
          <Box>
            <StaticImage
              src="../images/blackpages-io.png"
              alt="BlackPages.io"
              placeholder="blurred"
              width={500}
              height={260}
            />
          </Box>
          <Box my={3}>
            <Text>
              Made for my MBT capstone project as the lead technical advisor
            </Text>
            <Text>Built with: Next.js, Bulma, Strapi, and GraphQL</Text>
          </Box>
        </Box>
      </SimpleGrid>
    </>
  );
};

export default Projects;
